/* global $*/
import '../../css/app/components/loginRegister.scss';

class LoginPage
{
    constructor() {
        this.showPassword();
    }

    showPassword()
    {
        $(".toggle-password").on('click', function(e) {
            e.preventDefault();
            $(this).find('i').toggleClass("fa-eye-slash");
            var input = $(this).parent().find("#password");
            if (input.attr("type") === "password") {
                input.attr("type", "text");
            } else {
                input.attr("type", "password");
            }
        });
    }
    
}
new LoginPage;
